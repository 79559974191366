import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=768401ee&scoped=true"
import script from "./NavBar.vue?vue&type=script&lang=js"
export * from "./NavBar.vue?vue&type=script&lang=js"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=768401ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768401ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme1DesktopNavLoginPopup: require('/var/app/components/Theme1/Desktop/Nav/LoginPopup.vue').default,Theme1DesktopNavProfileDropdown: require('/var/app/components/Theme1/Desktop/Nav/ProfileDropdown.vue').default,LanguageSwitcher: require('/var/app/components/LanguageSwitcher.vue').default,Theme1DesktopMenuBar: require('/var/app/components/Theme1/Desktop/MenuBar.vue').default,Theme2DesktopLoginForm: require('/var/app/components/Theme2/Desktop/LoginForm.vue').default,Theme2DesktopRegisterForm: require('/var/app/components/Theme2/Desktop/RegisterForm.vue').default})
