
export default {
    data() {
        return {
            useContainer: true,
        }
    },
    methods: {
        onResize() {
            const x = window.innerWidth
            if (x < 1915) {
                this.useContainer = false
            } else {
                this.useContainer = true
            }
        },
    },
}
