
import { parsePhoneNumber } from 'awesome-phonenumber'
export default {
    props: {
        hideDetails: {
            type: [Boolean, String],
            default: null,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        value: {
            type: String,
            default: '',
        },
        currentValue: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: null,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        defaultCountry: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        phone: null,
        generalInquiryValid: true,
    }),
    computed: {
        formRule() {
            return [
                (v) =>
                    !v ||
                    /^[\d\s ()+\\-]+$/.test(v) ||
                    this.tl('phoneNumberFormatInvalid'), // this.tl('phoneNumberMustNotContainChar'),
                (v) =>
                    !v ||
                    this.generalInquiryValid ||
                    this.tl('phoneNumberFormatInvalid'),
                ...this.rules,
            ]
        },
    },
    watch: {
        value(value) {
            this.phone = value?.toString()
        },
    },

    mounted() {
        this.phone = this.value?.toString()
    },
    methods: {
        onValidateChange(obj) {
            this.generalInquiryValid = obj.isValid
            this.$emit('input', obj.number.e164)
        },
        onCountryChange(evt) {
            this.$emit('country-changed', evt)
            if (!this.phone || evt.iso2) {
                return null
            }
            const pn = parsePhoneNumber(
                this.phone?.toString(),
                evt?.iso2?.toString()
            )
            this.generalInquiryValid = pn.g.valid
        },
    },
}
